<template>
    <div class="backdrop">
        <div class="modal" :class="{sale: theme === 'sale'}">
            <h1>{{ header }}</h1>
            <p>{{ text }}</p>
        </div>
        <div class="semblance-photo">
            <div class="photo">
                <img src="../assets/20240218_000301.jpg" alt="David" width="235" height="312">
            </div>
            <div>
                <div v-for="(item, index) in items" :key="index">
                    <button @click="toggleDetails(index)">
                        {{ item.title }}
                    </button>
                    <div v-show="item.showDetails" class="additional-info">
                        <p>{{ item.description }}</p>
                        <div v-show="item.showArticles" class="additional-info-articles">
                            <li v-for="(item, index) in articulos" :key="index">
                              <a :href="item.url" target="_blank">{{ item.title }}</a> - {{ item.year }}
                            </li>
                        </div>
                    </div>

                </div>
            </div>

        </div>
    </div>
</template>

<script>
export default{
    data() {
    return {
        items: [
        { title: "Semblance", description: "Hi! I'm David Herrera Sánchez, linving now in Xalapa, Veracruz . I'm a graduate of the Universidad Veracruzana in Electronic Instrumentation Engineering and a Master's degree in Artificial Intelligence, graduated with honorific mention. At this moment I'm a PhD student in Artificial Intelligence at Universidad Veracruzana. I ve participated in the National Computing Meeting (ENC) giving various tutorials. I obtained 2nd place in the RetoInnova-World in collaboration with the Metropolitan Technological Institute, Medellin, Colombia. My hobbies are Front-end and Backend practice. As well as learning new technologies in Artificial Intelligence. Areas of interest are computer vision, machine learning, and evolutionary computing. ", showDetails: true },
        { title: "Scientific Production", showDetails: true,showArticles: true },
        { title: "Contact", description: "davherrera@uv.mx", showDetails: false }
      ],
      articulos:[
                {title: 'Experimental Study for Automatic Feature Construction to Segment Images of Lungs Affected by COVID-19 Using Genetic Programming', 
                    year: 2025, 
                    url: "https://doi.org/10.1007/978-3-031-83882-8_15", 
                    showArticles: true },
                {title: 'Imaging Estimation for Liver Damage Using Automated Approach Based on Genetic Programming', 
                    year: 2025, 
                    url: "https://doi.org/10.3390/mca30020025", 
                    showArticles: true },
                {title: 'Feature selection, construction and search space reduction based on genetic programming for high-dimensional datasets', 
                    year: 2024, 
                    url: "https://link.springer.com/article/10.1007/s00521-024-10567-7", 
                    showArticles: true },
                {title: 'Shifting Color Space for Image Classification using Genetic Programming', 
                    year:2024, conference:"GECCO 24'",
                    url:"https://dl.acm.org/doi/10.1145/3638530.3654430", 
                    showArticles:true},
                {title: 'AutoML Based on Genetic Programming for Medical Image Classification', 
                    year:2023,conference:" MICAI - CIAPP",
                    url:"https://link.springer.com/chapter/10.1007/978-3-031-51940-6_26",
                    showArticles: true},
                {title: 'Feature Construction, Feature Reduction and Search Space Reduction Using Genetic Programming', 
                    year:2022, conference:"ISCMI 2022",
                    url:"https://ieeexplore.ieee.org/abstract/document/10068452/", 
                    showArticles:true}
                
            ]
    };
  },
  methods: {
    toggleDetails(index) {
      this.items[index].showDetails = !this.items[index].showDetails;
    },
    toggleArticles(index) {
      this.articulos[index].showArticles = !this.items[index].showArticles;
    }
  },
    props:['header','text','theme']
}
</script>

<style scoped>


button {
  background-color:  rgb(41, 75, 88);
  color: white;
  width: 95vw;
  height: auto;
  margin: 5px;
  padding: 10px 10px;
  border: none;
  border-radius: 100px;
  cursor: pointer;
}

button:hover {
  background-color: #5d637e;
}
.backdrop{
    background: rgba(0, 0, 0, 0.35);
    width:95vw;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    overflow-x: hidden; /* Evita barras de desplazamiento horizontales */


}
.additional-info {
  border: 1px solid #ccc;
  width:auto;
  border-radius: 10px;
  padding: 10px;
  margin-top: 5px;
  overflow-x: hidden; /* Evita barras de desplazamiento horizontales */
}
.modal{
    font-style: normal;
    text-align: left;
    width: 100vh;
    padding: 20px;
    margin: 0 auto;
    background: white;
    /*border-radius: 10px;*/
}


.modal h1{
    color:azure;
    border: none;
    width: auto;
    padding: 0;
}

.modal p{
    font-style: italic;
}
.modal.sale{
    background: rgb(41, 75, 88);
    width: 95vw;
    color: white;
}
.semblance-photo{
    display: flex;
    flex-direction: column;
    align-content: flex;
}
</style>