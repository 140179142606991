<template>
  <Home :header="header" :text="text" theme="sale" />
</template>

<script>
import Home from './components/Home.vue'

export default {
  name: 'App',
  components: {Home},
  data(){
    return {
      title:'Personal Blog',
      header: 'MSc. David Herrera Sánchez',
      text: 'PhD. Student in Artificial Intelligence at Universidad Veracruzana'
    }
  },
  methods:{

  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
  margin: 0;
  padding: 0;
  position: absolute;
  min-height: 100% !important;
}
</style>
